import useBaseHook from 'themes/hooks/BaseHooks'
import { Result, Button } from 'antd';

const Error = () => {
    const { t, router } = useBaseHook()
    return <Result
        status="404"
        title={t("pages:errors.404.title")}
        subTitle={<div>{t("pages:errors.404.description")}</div>}
        extra={<Button type="primary" onClick={() => router.back()}>{t("back")}</Button>}
    />
}

Error.getInitialProps = () => {
    return {
        namespacesRequired: ['common', 'pages', 'menu']
    }
}
export default Error
